import axios from 'axios';
import type { AxiosInstance } from 'axios';
import { HyRequestInterceptors, HyRequestConfig } from './type';
import localCache from '@/utils/localCache';
import { Toast } from 'vant';
import { IRequestResult, IRequestResult2 } from '../types';

class HyRequest {
  instance: AxiosInstance;
  interceptors?: HyRequestInterceptors;

  constructor(config: HyRequestConfig) {
    //每次new HyRequest都要创建一个全新的axios的实例，参数config为创建的实例的默认的全局配置
    this.instance = axios.create(config);
    this.interceptors = config.interceptors;

    //拦截器种类一：axios所有实例共享的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        // console.log('axios所有实例共享的拦截器，请求成功拦截')
        //是否携带token
        const token = localCache.getItem('token');
        // const token = 'dd7b2be640084872b38ce19bf72e40cd'
        if (token) {
          if (config.headers) {
            // config.headers['Content-Type'] = 'application/json';
            config.headers.token = token;
          }
        }

        return config;
      },
      (error) => {
        // console.log('axios所有实例共享的拦截器，请求失败拦截')
        return error;
      }
    );
    this.instance.interceptors.response.use(
      (response) => {
        // console.log('axios所有实例共享的拦截器，响应成功拦截', response)

        const data = response.data;
        //假设接口返回的code为0为响应成功，为1为响应失败
        if (data.status == 1 || data.code === '200') {
          return data;
        } else {
          //响应失败
          return data;
          // Toast(data.info)
          // throw new Error(data.info)
          // console.log(data.message)
        }
      },
      (error) => {
        // console.log('axios所有实例共享的拦截器，响应失败拦截')

        //响应失败
        return error;
      }
    );

    //拦截器种类二：创建的当前的axios实例的拦截器，拦截器需要在创建当前实例时通过传参传进来，所以需要扩展构造器接受的参数类型
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptors,
      this.interceptors?.requestInterceptorsCatch
    );
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptors,
      this.interceptors?.responseInterceptorsCatch
    );
  }

  request<T>(config: HyRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      //拦截器种类三：具体某个请求的拦截器，拦截器需要在发送请求的时候，通过参数传进来
      if (config.interceptors?.requestInterceptors) {
        // console.log('具体某个请求的拦截器，请求成功拦截')
        config = config.interceptors.requestInterceptors(config);
      }

      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptors) {
            // console.log('具体某个请求的拦截器，响应成功拦截')
            res = config.interceptors.responseInterceptors(res);
          }
          // if (res.status == 1 || res.code === '200') {
          //   resolve(res)
          // } else {
          //   //响应失败
          //   reject(res)
          // }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  get<T>(config: HyRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' });
  }
  post<T>(config: HyRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' });
  }
  delete<T>(config: HyRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' });
  }
  patch<T>(config: HyRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' });
  }
  put<T>(config: HyRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PUT' });
  }
}

export default HyRequest;
