/**
 * 根据process.env.NODE_ENV值区分环境
 *  development: 开发环境
 *  production: 生产环境
 *  test: 测试环境
 */

let base_url = ''
let base_url1 = ''
let base_url2 = ''
let base_url3 = ''
let base_url4 = ''
const time_out = 20000
if (process.env.NODE_ENV === 'development') {
  base_url = '/api'
  // base_url = 'http://api-v4.guanjia.com.zydl-tec.cn:8088/ksgj4.0' //开发环境地址
  base_url1 = '/lterpapi'
  base_url2 = '/tableapi'
  base_url3 = '/api' // 原oa地址已转移
  base_url4 = '/easybuyapi'
  // base_url2 = 'https://h5-v4-report.guanjia.zydl-tec.cn/tableapi'
  // base_url = 'https://api-v4.guanjia.zydl-tec.cn/ksgj4.0' //正式环境地址
  // base_url = 'http://192.168.1.171:8083' //森森
} else if (process.env.NODE_ENV === 'production') {
  base_url = '/api'
  base_url1 = '/lterpapi'
  base_url2 = '/tableapi'
  base_url3 = '/api'
  base_url4 = '/easybuyapi'
  // base_url = 'https://api-v4.guanjia.zydl-tec.cn/ksgj4.0' //正式环境地址

  // base_url1 = 'http://huzhixin.sale-admin.zydl-tec.cn:8089/'
  // base_url2 = 'http://obs.wl.zydltec.com:51115/ws'

  // base_url2 = 'http://api-v4.guanjia.com.zydl-tec.cn:8088/ksgj4.0'

  // base_url3 = 'http://39.105.78.155:8080'
} else {
  base_url = 'http://api-v4.guanjia.com.zydl-tec.cn:8088/ksgj4.0' //测试环境地址
  base_url1 = 'http://lterp.sale-admin.zydl-tec.cn:8089'
  base_url2 = 'http://obs.wl.zydltec.com:51115/ws'
  base_url3 = 'http://39.105.78.155:8080'
  base_url4 = 'http://easybuy-api.lt.188.zydl-tec.cn:8089'
}
export { base_url, time_out, base_url1, base_url2, base_url3, base_url4 }
