import HyRequest from './request'
import {
  base_url,
  time_out,
  base_url1,
  base_url2,
  base_url3,
  base_url4
} from './request/config'
export const hyRequest1 = new HyRequest({
  //这里面传的配置是创建的axios实例默认的全局配置
  baseURL: base_url1,
  timeout: time_out,
  interceptors: {
    requestInterceptors: (config) => {
      // console.log('创建的当前的axios实例的拦截器，请求成功拦截')
      return config
    },
    requestInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，请求失败拦截')
      return error
    },
    responseInterceptors: (response) => {
      // console.log('创建的当前的axios实例的拦截器，响应成功拦截')
      return response
    },
    responseInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，响应失败拦截')
      return error
    }
  }
})
export const hyRequest2 = new HyRequest({
  //这里面传的配置是创建的axios实例默认的全局配置
  baseURL: base_url2,
  timeout: time_out,
  interceptors: {
    requestInterceptors: (config) => {
      // console.log('创建的当前的axios实例的拦截器，请求成功拦截')
      return config
    },
    requestInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，请求失败拦截')
      return error
    },
    responseInterceptors: (response) => {
      // console.log('创建的当前的axios实例的拦截器，响应成功拦截')
      return response
    },
    responseInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，响应失败拦截')
      return error
    }
  }
})
export const hyRequest3 = new HyRequest({
  //这里面传的配置是创建的axios实例默认的全局配置
  baseURL: base_url3,
  timeout: time_out,
  interceptors: {
    requestInterceptors: (config) => {
      // console.log('创建的当前的axios实例的拦截器，请求成功拦截')
      return config
    },
    requestInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，请求失败拦截')
      return error
    },
    responseInterceptors: (response) => {
      // console.log('创建的当前的axios实例的拦截器，响应成功拦截')
      return response
    },
    responseInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，响应失败拦截')
      return error
    }
  }
})
export const hyRequest4 = new HyRequest({
  //这里面传的配置是创建的axios实例默认的全局配置
  baseURL: base_url4,
  timeout: time_out,
  interceptors: {
    requestInterceptors: (config) => {
      // console.log('创建的当前的axios实例的拦截器，请求成功拦截')
      return config
    },
    requestInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，请求失败拦截')
      return error
    },
    responseInterceptors: (response) => {
      // console.log('创建的当前的axios实例的拦截器，响应成功拦截')
      return response
    },
    responseInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，响应失败拦截')
      return error
    }
  }
})
export default new HyRequest({
  //这里面传的配置是创建的axios实例默认的全局配置
  baseURL: base_url,
  timeout: time_out,
  interceptors: {
    requestInterceptors: (config) => {
      // console.log('创建的当前的axios实例的拦截器，请求成功拦截')
      return config
    },
    requestInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，请求失败拦截')
      return error
    },
    responseInterceptors: (response) => {
      // console.log('创建的当前的axios实例的拦截器，响应成功拦截')
      return response
    },
    responseInterceptorsCatch: (error) => {
      // console.log('创建的当前的axios实例的拦截器，响应失败拦截')
      return error
    }
  }
})
